<!-- =========================================================================================
    File Name: ForgotPassword.vue
    Description: FOrgot Password Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="h-screen flex w-full bg-img">
    <div class="vx-col w-4/5 sm:w-4/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row">
            <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/1 mx-auto self-center">
              <vs-list>
                <vs-list-item :title="$t('app-version')" :subtitle="this.$store.getters.appVersion"></vs-list-item>
                <vs-list-item :title="$t('api-version')" :subtitle="apiVersion"></vs-list-item>
              </vs-list>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>

import Rest from '../../services/Rest'

export default {
  data() {
    return {
      apiVersion: ''
    }
  },
  computed: {
    
  },
  methods: {
    getApiVersion() {
      this.$vs.loading()
      const service = Rest.build()
      service.get('/api/v1/version').then(response => {
        this.apiVersion = response.version
        this.$vs.loading.close()
      })
    }
  },
  created() {
    this.getApiVersion()
  }
}
</script>
